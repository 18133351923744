import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Divider, Layout, Menu, Popover } from "antd";
import { useMediaQuery } from "react-responsive";
import type { MenuProps } from "antd";
import type { CustomRoute } from "@/types";
import clsx from "clsx";
import { compose, take, tail, split } from "ramda";
import { colors } from "@/config/theme";
import { useAuthContext } from "@/contexts/auth-context";
import LogoutIcon from "@/components/logout-icon";
import PeopleIcon from "@/components/people-icon";
import Note2Icon from "@/components/note2-icon";
import MoneyIcon from "@/components/money-icon";
import ChartIcon from "@/components/chart-icon";
import arrowDownIcon from "@/assets/arrow-down.svg";
import brandLogo from "/brand-logo.svg";
import avatarIcon from "/user-avatar.svg";
import LanguageSwitcher from "@/components/languagesSwiper";

interface Props {
  children: React.ReactElement;
  sidebarRoutes: CustomRoute[];
}

const { Header } = Layout;

// "/list-of-acts/9382" -> ["list-of-acts"]
type getTopRouteFn = (str: string) => string[];
// getTopRoute :: string -> [string] -> [string] -> [string]
const getTopRoute = compose(take(1), tail, split("/")) as getTopRouteFn;

export default function DefaultLayout(props: Props): React.ReactElement {
  const { children, sidebarRoutes } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setIsAuth } = useAuthContext();

  const [open, setOpen] = useState(false);

  const menuKeys = getTopRoute(pathname);

  const hide = (): void => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const logout = (): void => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    hide();

    setIsAuth(false);
  };

  const items = useMemo(
    // eslint-disable-next-line arrow-body-style
    (): MenuProps["items"] => {
      return sidebarRoutes.slice(0, 4).map(({ path, title }) => ({
        key: path as string,
        label: (
          <span className={clsx("flex items-center h-full gap-6")}>
            {t(title ?? "")}
          </span>
        ),
        onClick: () => {
          navigate(path as string);
        },
        style: {},
      }));
    },
    [sidebarRoutes, navigate, t],
  );

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const position = localStorage.getItem("position");
  const isAdmin = position !== "kassir";

  return (
    <Layout>
      {isTabletOrMobile ? (
        <Header className="sticky top-0 z-[1] w-full bg-white border-b-[1px] border-white pl-6 flex justify-between items-center">
          <span className="text-[18px] font-semibold">{t("reports")}</span>
          <Popover
            content={
              <div className="flex flex-col gap-3">
                <Button
                  type="text"
                  className="flex items-center"
                  onClick={logout}
                >
                  <LogoutIcon style={{ color: colors.danger }} /> {t("logout")}
                </Button>
              </div>
            }
            trigger="click"
            open={open}
            arrow={false}
            onOpenChange={handleOpenChange}
          >
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H4Z"
                  fill="#363940"
                />
              </svg>
            </div>
          </Popover>
        </Header>
      ) : (
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            background: colors.bg_primary,
          }}
        >
          <img
            src={brandLogo}
            alt="brand-logo"
            className="cursor-pointer"
            onClick={() => {
              navigate("/orders");
            }}
            aria-hidden
          />

          <div className="w-full flex justify-between items-center">
            <Menu
              mode="horizontal"
              selectedKeys={menuKeys}
              items={items}
              className="flex-1 bg-[#f5f5f5] border-b-0"
            />

            <div className="flex gap-6 items-center">
             {/* <div>en</div> */}
             <LanguageSwitcher />


              <Popover
                content={
                  <div className="flex flex-col gap-3">
                    {isAdmin ? (
                      <>
                        <Button
                          type="text"
                          className="flex items-center"
                          onClick={() => {
                            navigate("/reports");
                            hide();
                          }}
                        >
                          <ChartIcon style={{ color: colors.primary }} />{" "}
                          {t("reports")}
                        </Button>

                        <Button
                          type="text"
                          className="flex items-center"
                          onClick={() => {
                            navigate("/expenses");
                            hide();
                          }}
                        >
                          <MoneyIcon style={{ color: colors.primary }} />{" "}
                          {t("expenses")}
                        </Button>
                        {isAdmin ? (
                          <Button
                            type="text"
                            className="flex items-center"
                            onClick={() => {
                              navigate("/employee-control");
                              hide();
                            }}
                          >
                            <PeopleIcon style={{ color: colors.primary }} />{" "}
                            {t("employee-control")}
                          </Button>
                        ) : null}
                        <Button
                          type="text"
                          className="flex items-center"
                          onClick={() => {
                            navigate("/complaints");
                            hide();
                          }}
                        >
                          <Note2Icon style={{ color: colors.primary }} />{" "}
                          {t("complaints")}
                        </Button>
                        <Divider className="my-0" />
                      </>
                    ) : null}
                    <Button
                      type="text"
                      className="flex items-center"
                      onClick={logout}
                    >
                      <LogoutIcon style={{ color: colors.danger }} />{" "}
                      {t("logout")}
                    </Button>
                  </div>
                }
                trigger="click"
                open={open}
                arrow={false}
                onOpenChange={handleOpenChange}
              >
                <div className="flex gap-2 w-40 cursor-pointer">
                  <img src={avatarIcon} alt="profile" />

                  <div className="w-full relative">
                    <div className="absolute top-0 text-sm font-medium flex items-center gap-1">
                      <span>{localStorage.getItem("username")}</span>
                      <img src={arrowDownIcon} alt="arrow-down" />
                    </div>
                    <span className="absolute top-5 text-xs text-[#5566FF]">
                      Administrator
                    </span>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </Header>
      )}

      {children}
    </Layout>
  );
}
