/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import type { PaginationProps } from "antd";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getOrders, getPaymentTypes } from "@/features/all-orders";
import { colors } from "@/config/theme";
import { uid } from "radash";
import dayjs from "dayjs";
import formatAmount from "@/helpers/format-amount";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Order } from "@/features/all-orders";
import type { OrderType } from "@/types";
import DownloadIcon from "@/components/download-icon";
import clsx from "clsx";
import {
  createClient,
  downloadExcel,
  getDebtClients,
  getOrderExpenseStats,
  getOrderRaiseStats,
  getOrderTypeStats,
  getOrderedProductsStats,
  getPaymentTypeStats,
  getProductCategoryStats,
  payAllDebt,
} from "../api";
import OrderTypeIcon from "@/components/order-type-icon";
import "dayjs/locale/ru";
import TotalStatsCard from "../components/total-stats-card";
import SalesChart from "../components/sales-chart";
import PaymentChart from "../components/payment-chart";
import PreferencesIcon from "@/components/preferences-icon";
import LogoutIcon from "@/components/logout-icon";
import { useAuthContext } from "@/contexts/auth-context";
import MobileOrderCard, {
  MobileOrderCardSkeleton,
} from "../components/mobile-order-card";
import EggsIcon from "@/components/eggs-icon";
import DishIcon from "@/components/dish-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import MoneyIcon from "@/components/money-icon";
import { useNavigate } from "react-router-dom";
import AddIcon from "@/components/add-icon";
import SearchIcon from "@/components/search-icon";
import BaseClientIcon from "@/components/base-client-icon";
import NoteDeptIcon from "@/components/note-debt-icon";
import DeptPayIcon from "@/components/dept-pay-icon";
import queryClient from "@/utils/query-client";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

interface Filters {
  self: boolean;
  shipping: boolean;
  min_date: string | null;
  max_date: string | null;
  payment_type: number | undefined;
  date_type: string | undefined;
  debtor_clients: boolean;
}

export default function Clients(): React.ReactElement {
  const { t } = useTranslation();
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [productsPageSize, setProductsPageSize] = useState(10);
  const [productsPage, setProductsPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState<number>();

  const [open, setOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("orders-list");
  const form = Form.useForm()[0];

  const [currDebt, setCurrDebt] = useState<Order>();
  const [isPayAllModalOpen, setIsPayAllModalOpen] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
    debtor_clients: true,
    date: [null, null],
  });

  const [filtersDraft, setFiltersDraft] = useState<Filters>({
    search: "",
    start_date: null,
    end_date: null,
    debtor_clients: true,
  });

  const dateTypes = [
    { label: t("День"), value: "day" },
    { label: t("Неделя"), value: "week" },
    { label: t("Месяц"), value: "month" },
    { label: t("3 месяца"), value: "three_month" },
    { label: t("6 месяцев"), value: "six_month" },
    { label: t("Год"), value: "year" },
  ];
  const handleInputChange = (e) => {
    const value = e.target.value;
    setFiltersDraft((prev) => ({
      ...prev,
      search: value, // Update search term
    }));
  };

  const handleDateChange = (dates, dateStrings) => {
    setFiltersDraft((prev) => ({
      ...prev,
      start_date: dateStrings[0], // Update start date
      end_date: dateStrings[1], // Update end date
    }));
  };

  const defaultFilters = {
    debtor_clients: "not_paid",
  };

  const {
    data: ordersData,
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = useQuery({
    queryKey: ["orders", filtersDraft, page, pageSize],
    queryFn: async () => {
      const finishedFilters = {
        page,
        page_size: pageSize,
        search: filtersDraft.search, // Add search filter
        start_date: filtersDraft.start_date, // Add start date
        end_date: filtersDraft.end_date, // Add end date
        ...defaultFilters,
      };

      const res = await getDebtClients(finishedFilters);
      return res;
    },
    // enabled: !!filtersDraft.search || !!filtersDraft.start_date || !!filtersDraft.end_date // Optional: Only fetch when there's a search or date filter
  });

  // const totalCost =
  //   orders?.reduce((total, currOrder) => total + (currOrder.total ?? 0), 0) ??
  //   0;

  // const totalCost = (() => {
  //   if (Array.isArray(orders)) {
  //     return orders.reduce((total, currOrder) => {
  //       if (typeof currOrder.total === "number") {
  //         return total + currOrder.total;
  //       }
  //       return total;
  //     }, 0);
  //   }
  //   return 0;
  // })();

  const ordersPaginationProps: PaginationProps = {
    defaultCurrent: page,
    defaultPageSize: pageSize,
    total: ordersData?.count ?? 0,
    locale: { items_per_page: "" },
  };

  const clientCreation = useMutation({
    mutationFn: createClient,
    onSuccess: (data) => {
      console.log("Success:", data);

      void queryClient.invalidateQueries(["clients"]);
      handleCancel();
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  useEffect(() => {
    // Pre-fill phone number field with "+998" on component load or when the modal is opened
    form.setFieldsValue({
      phone_number: "+998",
    });
  }, [form, isModalOpen]);

  const onCreateOrder = (values) => {
    const phone_number = values.phone_number.replace("+", ""); // Remove "+" from phone number before sending
    clientCreation.mutate({
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number, // Send formatted phone number to backend
    });
  };

  const onPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const onProductsPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setProductsPage(pagination.current ?? 1);
    setProductsPageSize(pagination.pageSize ?? 10);
  };

  const hide = (): void => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const download = (): void => {
    void downloadExcel().then((res) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      link.download = `file_${uid(8, "-")}.xlsx`;
      link.click();
    });
  };

  const onApplyChanges = (): void => {
    setFiltersDraft(filters);
  };

  // console.log(currDebt, "bebt");

  const detailDebt = (): void => {
    navigate("/clients/debt-detail/2");
  };

  const columns = useMemo(() => {
    const ordersColumns: ColumnsType<Order> = [
      {
        key: "full-name",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("full-name-client")}
          </span>
        ),
        dataIndex: "fullName", // Use a computed key
        render: (text, record) => {
          // Access first_name and last_name from the record
          const fullName = `${record.first_name} ${record.last_name}`;
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {fullName}
            </span>
          );
        },
      },
      {
        key: "phone-number",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("phone_number")}
          </span>
        ),
        dataIndex: "phone_number",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "debts-count",
        title: (
          <span className="font-medium text-[#7D848B]">{t("all-order")}</span>
        ),
        dataIndex: "debts_count",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {value} {t("only-order")}
            </span>
          );
        },
      },
      {
        key: "order-id",
        title: (
          <span className="font-medium text-[#7D848B]">{t("dept-summ")}</span>
        ),
        dataIndex: "debts_amount",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {formatAmount(value ?? 0)} UZS
            </span>
          );
        },
      },
      {
        key: "order-id",
        width: 350,
        title: (
          <span className="font-medium text-[#7D848B]">{t("action")}</span>
        ),
        dataIndex: "id",
        render(record, value: string) {
          return (
            <span className="flex gap-4">
              <Button
                className="flex items-center bg-[#7D858B33] border-none"
                onClick={() => {
                  setCurrDebt(record);
                  // detailDebt()
                  navigate(`/clients/debt-detail/${record}`);
                }}
              >
                <NoteDeptIcon />
                {t("view")}
              </Button>
              <Button
                onClick={() => {
                  setIsPayAllModalOpen(true);
                  setCurrDebt(value);
                }}
                className="flex items-center text-[#5566FF] bg-[#5566FF10] border-none"
              >
                <DeptPayIcon />
                {t("pay")}
              </Button>
            </span>
          );
        },
      },
    ];

    const productsColumns: ColumnsType<Order> = [
      {
        key: "name-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("product-name")}
          </span>
        ),
        dataIndex: "name",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "category",
        title: (
          <span className="font-medium text-[#7D848B]">{t("category")}</span>
        ),
        dataIndex: "category",
        render(value) {
          function getIcon(id: number): React.ReactElement | null {
            if (id === 3) return <EggsIcon />;
            if (id === 4) return <DishIcon />;
            if (id === 2) return <CoffeeIcon />;
            if (id === 1) return <ChocolateIcon />;
            if (id === 5) return <BrocoliIcon />;
            if (id === 6) return <CakeIcon />;

            return null;
          }

          console.log(value);
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
              {getIcon(value.id)}
              {value.name}
            </Tag>
          );
        },
      },
      {
        key: "count",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("sales-number")}
          </span>
        ),
        dataIndex: "count",
        render(value) {
          return (
            <span className="text-sm whitespace-nowrap text-[#2F3138]">
              {value} {t("piece")}
            </span>
          );
        },
      },
      {
        key: "price",
        title: (
          <span className="font-medium text-[#7D848B]">{t("unit-price")}</span>
        ),
        dataIndex: "price",
        render(value) {
          return (
            <span className="text-sm text-[#2F3138]">
              {formatAmount(value)} UZS
            </span>
          );
        },
      },
      {
        key: "total",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("total-price-sales")}
          </span>
        ),
        dataIndex: "total",
        render(value: number, vals) {
          return (
            <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
              {formatAmount(vals.count * vals.price ?? 0)} UZS
            </span>
          );
        },
      },
    ];
    return { orders: ordersColumns, products: productsColumns };
  }, [t, activeTab]);

  console.log(currDebt?.id, "sdsd");
  const amountDefaultValue = currDebt?.debts_amount;
  const amountDefaultId = currDebt?.id;

  const showModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsModalOpen(true);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const logout = (): void => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    hide();

    setIsAuth(false);
  };

  const onShowMore = () => {
    setPageSize((prev) => prev + 10);
  };

  const onClearFilters = (): void => {
    const clearedFilters = {
      self: true,
      shipping: true,
      min_date: null,
      max_date: null,
      date_type: undefined,
      payment_type: undefined,
    };
    setFilters(clearedFilters);
    setFiltersDraft(clearedFilters);
  };

  const tabsItems = useMemo(() => {
    const tabsAllItems: TabsProps["items"] = [
      {
        key: "sold-items",
        label: <span className="text-lg font-medium">{t("sold-items")}</span>,
      },
      {
        key: "orders-list",
        label: <span className="text-lg font-medium">{t("orders-list")}</span>,
      },
    ];

    return tabsAllItems;
  }, [t]);

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
  };

  const all_debt_sum = ordersData?.total_debts;

  // Check if all_debt_sum is defined and is a number, else fallback to 0
  const displayDebtSum =
    typeof all_debt_sum === "number" ? formatAmount(all_debt_sum) : "0";

  const payDebtAllCreation = useMutation({
    mutationFn: payAllDebt,
    onSuccess: (data) => {
      console.log("Success:", data);
      setIsPayAllModalOpen(false);
      handleCancel();
      void queryClient.invalidateQueries([
        "orders",
        filtersDraft,
        page,
        pageSize,
      ]);
      // void queryClient.invalidateQueries(["debtor", id]);
      // onSuccessNotify(`${t("Заказ")} ${data?.id} ${t("оплачен")}`);
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const onCreateAllDebt = (values): void => {
    const numericAmount = parseInt(values.amount, 10); // Convert amount to a number (removes non-numeric characters)

    payDebtAllCreation.mutate({
      client: amountDefaultId, // Use form values
      amount: amountDefaultValue, // Use only the numeric value
      payment_type: values.payment_type,
    });
  };
  const { data: paymentTypesData } = useQuery({
    queryKey: ["payment-types"],
    queryFn: async () => {
      const res = await getPaymentTypes();
      return res;
    },
  });
  const options =
    paymentTypesData?.results?.map((item) => ({
      value: item.id, // Assuming each item has an 'id' field to use as the value
      label: item.translations?.ru?.name, // Assuming each item has a 'name' field to display as the label
    })) || [];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <Header className="bg-white grid grid-cols-3 items-center fixed bottom-0 z-[1] justify-between border-y-[1px] px-4">
          <Button
            className="border-none flex flex-col gap-1 shadow-none items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(false);
            }}
          >
            <PreferencesIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("filter")}</span>
          </Button>
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={download}
          >
            <DownloadIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">
              {t("download-excel")}
            </span>
          </Button>
          {/* <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              setOpen(true);
              setIsLoggingOut(true);
            }}
          >
            <LogoutIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("logout")}</span>
          </Button> */}
          <Button
            className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
            onClick={() => {
              navigate("/expenses");
              // hide();
            }}
          >
            <MoneyIcon style={{ color: colors.primary }} />
            <span className="text-[10px] text-[#35314D]">{t("expenses")}</span>
          </Button>

          <Drawer
            placement={"bottom"}
            closable={false}
            style={{
              borderRadius: "16px 16px 0 0",
              overflow: "hidden",
            }}
            height="auto"
            classNames={{ body: "!p-0 !pt-4" }}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Button
              className="flex justify-center w-full h-auto"
              type="text"
              onClick={() => setOpen(false)}
            >
              <div className="w-9 h-1 rounded-lg bg-[#A8AAB3]/30"></div>
            </Button>
            <h2 className="text-base text-center font-semibold my-3 text-[#35314D]">
              {isLoggingOut ? t("Подтвердите действие") : t("filter")}
            </h2>
            <Divider className="m-0" />
            <div className="p-6">
              {isLoggingOut ? (
                <div className="flex flex-col items-center justify-center gap-6">
                  <div className="flex flex-col gap-4 items-center">
                    <LogoutIcon style={{ color: "#FF1F00" }} />
                    <span className="w-80 text-center">
                      {t("confirm-logout")}
                    </span>
                  </div>

                  <div className="grid grid-cols-2 gap-4 w-full">
                    <Button
                      size="large"
                      className="bg-[#f5f5f5] border-none"
                      type="text"
                      onClick={() => setOpen(false)}
                    >
                      {t("cancel")}
                    </Button>

                    <Button
                      size="large"
                      className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                      onClick={() => {
                        isLoggingOut && logout();
                      }}
                    >
                      {t("Выйти")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className=" mb-6">
                    <h1 className="font-medium mb-3 text-base">
                      Выберите промежуток
                    </h1>
                    <Radio.Group
                      value={filters.date_type}
                      onChange={(e: any) => {
                        setFilters((prevFilters) => {
                          return {
                            ...prevFilters,
                            date_type: e.target.value,
                            min_date: null,
                            max_date: null,
                          };
                        });
                      }}
                    >
                      <Space direction="vertical">
                        {dateTypes.map((val) => (
                          <Radio key={val.label + val.value} value={val.value}>
                            {val.label}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                  <h1 className="font-medium mb-3 text-base">
                    Выберите период
                  </h1>
                  <div className="grid grid-cols-2 gap-4">
                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("from") ?? ""}
                      value={
                        filters.min_date
                          ? dayjs(filters.min_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          min_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />

                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={t("to") ?? ""}
                      value={
                        filters.max_date
                          ? dayjs(filters.max_date, "DD.MM.YYYY")
                          : null
                      }
                      onChange={(_, stringDate) =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          max_date: stringDate,
                          date_type: undefined,
                        }))
                      }
                      inputReadOnly
                    />
                    <Button
                      type="primary"
                      onClick={onClearFilters}
                      className="flex items-center text-[#2F3138] bg-[#F5F5F5] justify-center"
                      loading={isLoading}
                    >
                      {t("Очистить")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={onApplyChanges}
                      className="flex items-center justify-center"
                      loading={isLoading}
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Drawer>
        </Header>
      ) : (
        <Header className="bg-white flex items-center justify-between border-y-[1px]">
          <h1 className="text-2xl font-semibold">{t("clients")}</h1>
          <div className="flex items-center gap-6">
            <div className="flex flex-col items-center">
              <span className="text-sm font-normal">{t(`all-depts-summ`)}</span>
              <span className="text-lg font-semibold">
                {formatAmount(displayDebtSum)} UZS
              </span>
            </div>
            <Button
              type="primary"
              className="flex items-center"
              size="large"
              onClick={() => {
                showModal();
              }}
            >
              <AddIcon />
              {t("add-client")}
            </Button>
          </div>
        </Header>
      )}
      <Content className="px-4 my-4 grid grid-cols-6">
        <div className="col-span-4">
          <div className="flex gap-6">
            <Input
              className="w-full p-3 rounded-[6px] border-none"
              placeholder={t("search-client") ?? ""}
              prefix={<SearchIcon />}
              onChange={handleInputChange} // Har bir harf kiritilishi bilan so'rov yuboriladi
            />
          </div>
        </div>
        <div className="flex items-center gap-2 px-4 col-span-2">
          <Button
            type="primary"
            onClick={() => {
              navigate("/clients/debts-client");
            }}
            className="flex items-center text-[#5566FF] justify-center h-12 bg-white "
          >
            <BaseClientIcon />
            {t("data-clients")}
          </Button>

          <RangePicker
            className="h-12 border-none"
            placeholder={[t("from"), t("to")]}
            // value={getDateVal(filters.date)}
            onChange={handleDateChange}
          />
        </div>
      </Content>
      <Content className="bg-[#f5f5f5] px-4 py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                height: isTabletOrMobile
                  ? "calc(100vh - 64px - 64px - 24px - 24px - 80px - 56px - 77px - 16px - 16px - 1px)"
                  : "calc(100vh - 64px - 64px - 60px - 24px - 24px - 55px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-reports")}
                </span>
              </div>
            </div>
          )}
        >
          {isTabletOrMobile ? (
            <h1 className="text-lg font-semibold mb-6">
              {t("orders-list")} ({ordersData?.count})
            </h1>
          ) : (
            <Table
              id="orders-list"
              rowKey={(record) => record.id}
              showHeader={true}
              columns={columns.orders}
              dataSource={ordersData?.results}
              onChange={onPageChange}
              pagination={ordersPaginationProps}
              loading={isOrdersLoading || isOrdersFetching}
              className={clsx(
                "overflow-x-auto bg-white",
                isTabletOrMobile
                  ? "custom-report-table-mobile"
                  : "custom-report-table",
                activeTab === "orders-list" ? "" : "hidden",
              )}
            />
          )}
          {/* {isTabletOrMobile ? (
            <div className="flex flex-col gap-3 pb-28">
              {orders?.length ? (
                orders?.map((val) => (
                  <MobileOrderCard
                    key={"mobile-order-card-" + val.id}
                    {...val}
                  />
                ))
              ) : ordersData?.count === 0 ? (
                <div className="flex flex-col items-center gap-2">
                  <NoteIcon style={{ color: colors.primary }} />
                  <span className="text-base leading-6 font-normal text-[#2F3138]">
                    {t("no-reports")}
                  </span>
                </div>
              ) : (
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                  <MobileOrderCardSkeleton key={"mobile-order-card-" + val} />
                ))
              )}
              {orders?.length === ordersData?.count ? null : (
                <Button
                  type="primary"
                  size="large"
                  className="bg-[#5566FF]/10 text-[#5566FF] font-medium text-sm"
                  disabled={!orders}
                  onClick={onShowMore}
                >
                  {t("show-ten-orders")}
                </Button>
              )}
            </div>
          ) : (
            <>
              <Table
                id="orders-list"
                rowKey={(record) => record.id}
                showHeader={true}
                columns={columns.orders}
                dataSource={ordersData?.results}
                onChange={onPageChange}
                pagination={ordersPaginationProps}
                loading={isOrdersLoading || isOrdersFetching}
                className={clsx(
                  "overflow-x-auto bg-white",
                  isTabletOrMobile
                    ? "custom-report-table-mobile"
                    : "custom-report-table",
                  activeTab === "orders-list" ? "" : "hidden",
                )}
              />
              <Table
                id="sold-items"
                rowKey={(record) => record.id}
                showHeader={true}
                columns={columns.products}
                dataSource={productsData?.results}
                onChange={onProductsPageChange}
                pagination={productsPaginationProps}
                loading={isProductsLoading || isProductsFetching}
                className={clsx(
                  "overflow-x-auto bg-white",
                  isTabletOrMobile
                    ? "custom-report-table-mobile"
                    : "custom-report-table",
                  activeTab === "sold-items" ? "" : "hidden",
                )}
              />
            </>
          )} */}
        </ConfigProvider>
      </Content>
      <Modal
        title={
          typeof modalId !== "undefined" ? t("edit-dish") : t("add-client")
        }
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={onCreateOrder}
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
        >
          <div className="flex justify-between gap-4 my-4 border-b-[1px]"></div>

          <Form.Item
            label={t("client-name")}
            name="first_name"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("client-surname")}
            name="last_name"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("phone_number")}
            name="phone_number"
            rules={[
              {
                required: true,
                pattern: /^\+998\d{9}$/, // Ensure phone number starts with +998 and has 9 digits after
                message: t("field-invalid-phone") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex justify-center gap-6">
            <Button
              type="primary"
              className="flex items-center py-[14px] px-6 bg-[#F5F5F5] text-[#2F3138] text-sm font-medium"
              size="large"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#5566FF]"
              size="large"
            >
              {typeof modalId !== "undefined"
                ? t("edit-dish")
                : t("add-client")}
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={t("pay_debt")}
        open={isPayAllModalOpen}
        footer={null}
        centered
        onCancel={() => setIsPayAllModalOpen(false)}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
          onFinish={onCreateAllDebt}
        >
          <Form.Item
            label={t("dept-summ")}
            name="amount"
            rules={[
              {
                // required: true,
                message: t("enter_amount"),
              },
            ]}
            className="relative"
          >
            <Input disabled value={amountDefaultValue} />{" "}
            <span className="absolute right-4 text-[#7D848B] text-sm font-normal top-1/2 transform -translate-y-1/2">
              UZS
            </span>
          </Form.Item>

          <Form.Item
            label={t("payment-method")}
            name="payment_type"
            rules={[
              {
                required: true,
                message: t("select_payment"),
              },
            ]}
          >
            <Select
              defaultValue=""
              onChange={handleChange}
              options={options} // Define your options here
            />
          </Form.Item>

          <div className="flex justify-center gap-6">
            <Button
              className="bg-[#F5F5F5] text-[#2F3138] font-medium"
              type="primary"
              onClick={() => setIsPayModalOpen(false)}
            >
              {t("otmena")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("pay")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

function getOrderType(filters: {
  self: boolean;
  shipping: boolean;
}): OrderType | undefined {
  const { self, shipping } = filters;

  if (self && shipping) return undefined;

  if (self) return "self";

  if (shipping) return "shipping";

  return undefined;
}
